import IconTennis from '../assets/icons/IconTennis';
import IconPadel from '../assets/icons/IconPadel';
import IconSquash from '../assets/icons/IconSquash';
import React from 'react';

export function RenderSportIcon({sportName, color}) {
  switch (sportName) {
    case 'Tennis' :
      return <IconTennis fill={color}/>;
    case 'Padel' :
      return <IconPadel fill={color}/>;
    case 'Squash' :
      return <IconSquash fill={color}/>;
    default:
      return;
  }
}

export function getInitials(name) {
  // Split the name into words
  let words = name.split(" ");

  if(words?.length>2){
    words = words?.slice(0,2);
  }
  // Map each word to its first letter and capitalize it
  const initials = words.map(word => word.charAt(0).toUpperCase());

  // Join the initials together
  return initials.join("");
}

export function groupByKey(array, key) {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
}

export const mergeObjects = (o1, o2) => {
  if(Object.keys(o1).length)
    // Use reduce to iterate over the keys of obj1
    return Object.keys({ ...o1, ...o2 }).reduce((acc, key) => {
      if (o1[key] && o2[key]) {
        // If both objects have the key, merge their arrays
        acc[key] = [...(o1[key] || []), ...(o2[key] || [])];
      } else {
        // Otherwise, take the value from whichever object has it
        acc[key] = o1[key] || o2[key];
      }
      return acc;
    }, {});
  return o2;
};

export const isTestClub=(clubId)=>{

  const ownClubs = [21687911,216880];
  return clubId? ownClubs.includes(Number(clubId)):false;
} 