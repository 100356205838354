import { Box, Button, Link, Stack, Typography, styled, useStepContext, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProfileImageViewer from '../../components/_Global/Profile/ProfileImageViewer';
import useClubExperienceContext from '../../hooks/clubExperience/useClubExperienceContext';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { useTranslate } from '@tolgee/react';
import { getCompanyDetails } from '../../vendor/redux/actions/company';
import { useDispatch } from 'react-redux';



const MobileAppBanner = (props) => {

    const { setView } = props

    const theme = useTheme();
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const {companyDetails} = useClubExperienceContext();

    const [osInfo, setOsInfo] = useState(null);

    const getAppLink = () => {

        const iosLink = companyDetails?.iosLink
        const andLink = companyDetails?.androidLink

        if (osInfo == 'iOS' && !['', null].includes(iosLink)) {

            return iosLink

        } else if (osInfo == 'Android' && !['', null].includes(andLink)) {

            return andLink
        }

        return ''

    }

    function getOS() {
        var uA = navigator.userAgent || navigator.vendor || window.opera;

        if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

        var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
        for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
    }

    useEffect(() => {
        setOsInfo(getOS())
    }, []);

    return (
        <BannerContainer
            link={getAppLink()}
        >

            <Typography variant={'body2'} fontWeight={400} textAlign={'start'}>
                <Close
                    sx={{ margin: '.3rem' }}
                    fontSize={'small'}
                    onClick={() => {
                        localStorage.setItem('viewMobileBanner', false);
                        setView(false);
                    }}
                />
            </Typography>

            <ProfileImageViewer
                borderRadius={osInfo == 'Android' ? '8px' : '18px'}
                borderColor={'transparent'}
                size={'md'}
                imgPath={companyDetails?.imageUrl}
                placeholderStyle={'shield-icon'}
            />
            <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                textAlign={'center'}
                margin={'.4rem 0 .4rem .6rem'}
                flex={'1'}
            >
                <Stack>
                    <ClubNameContainer
                        component='a'
                        variant={'h5-bold'}
                        lineHeight={1.1}
                        mb={.5}
                        color={'#000000'}
                        textAlign={'start'}
                        sx={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontSize: '20px',
                            margin: '0px'
                        }}
                        href={getAppLink()}
                        target='_blank'
                    >
                        {companyDetails?.name}
                    </ClubNameContainer>
                    <Typography variant={'body2'} fontWeight={400} textAlign={'start'}>
                        Sports
                    </Typography>
                </Stack>
                
            </Stack>
            <LinkButtonContainer>
                {
                    osInfo == 'iOS' ?
                        <Button
                        variant={'contained'}
                            component={'a'}
                            sx={{
                                borderRadius: '40px',
                                background: '#007AFF',
                                color: 'white',
                                padding: '7px 14px',
                                textWrap:'nowrap'
                            }}
                            href={getAppLink()}
                            target='_blank'
                        >
                            {t('global.mobileBanner.button.ios.text')}
                        </Button>
                        :
                        (
                            osInfo == 'Android' &&
                            <Button
                                variant={'contained'}
                                component={'a'}
                                sx={{
                                    borderRadius: '5px',
                                    background: '#01875F',
                                    color: 'white',
                                    padding: '7px 14px'
                                }}
                                href={getAppLink()}
                                target='_blank'
                            >
                                {t('global.mobileBanner.button.android.text')}
                            </Button>
                        )
                }
                </LinkButtonContainer>
        </BannerContainer>
    )
}

export default MobileAppBanner

const BannerContainer = styled(Stack)(({ theme, link }) => {
    
    return {
        display: ['', null].includes(link) ? 'none' : 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 16px 8px 14px'
    }


})

const ClubNameContainer = styled(Typography)(({ theme }) => {

    return {

        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: '-webkit-box',
        "-webkit-line-clamp": '2',
        "-webkit-box-orient": 'vertical',
        width: '100%',
        paddingRight:'2px'
    }
})


const LinkButtonContainer=styled(Stack)(()=>{

    return{
        direction:'row',
        flexBasis:'auto',
        alignItems:'end',
        justifyContent:'end'
    }
})