import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu, MenuItem,
  Stack,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowDropDown,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  MenuRounded,
} from '@mui/icons-material';
import {createContext, useContext, useState} from 'react';
import SiteLanguageSwitcher from './SiteLanguageSwitcher';
import ProfileImageViewer from '../Profile/ProfileImageViewer';
import {useNavigate} from 'react-router-dom';
import useUser from '../../../hooks/access/useUser';
import {useTranslate} from '@tolgee/react';
import useLogout from '../../../hooks/onboarding/useLogout';
import useIsAuthenticated from '../../../hooks/access/useIsAuthenticated';

const MobileNavContext = createContext();

function SiteMobileNav({navItems}) {

  const [isMobileNavDrawerOpen, setIsMobileNavDrawerOpen] = useState(false);
  const theme = useTheme();

  const user = useUser();
  const isAuthenticated = useIsAuthenticated();

  function handleOpenDrawer() {
    setIsMobileNavDrawerOpen(true);
  }

  function handleCloseDrawer() {
    setIsMobileNavDrawerOpen(false);
  }

  return (
      <MobileNavContext.Provider value={{handleOpenDrawer, handleCloseDrawer}}>
        <>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor:"pointer"
              }}
              onClick={handleOpenDrawer}
          >
            <MenuRounded fontSize={'large'} sx={{color: '#fff !important'}}/>
          </Box>
          <SwipeableDrawer
              anchor={'right'}
              open={isMobileNavDrawerOpen}
              onClose={handleCloseDrawer}
              onOpen={handleOpenDrawer}
          >
            <Stack
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                borderBottom={`1px solid ${theme.palette.divider}`}
                minWidth={'80vw'}
            >
              <IconButton
                  onClick={handleCloseDrawer}
                  sx={{mr: 'auto'}}
              >
                <ChevronRight fontSize={'large'} color={'action'}/>
              </IconButton>
              <Box pr={2}>
                <SiteLanguageSwitcher/>
              </Box>
            </Stack>
            {
                user &&
                <UserProfile user={user}/>
            }
            {
                !user &&
                <Login/>
            }
            <Divider/>
            <NavList isAuthenticated={isAuthenticated} navItems={navItems}/>
          </SwipeableDrawer>
        </>
      </MobileNavContext.Provider>
  );
}

function Login() {

}

function UserProfile({user: {userObj}}) {

  const name = `${userObj?.firstName} ${userObj?.lastName}`;
  const email = `${userObj.email}`;
  const profileImg = userObj?.imageUrl;

  const {t} = useTranslate();
  const {handleCloseDrawer} = useContext(MobileNavContext);

  const {handleUserLogout} = useLogout();

  const [menuEl, setMenuEl] = useState(null);

  const isOpenMenu = Boolean(menuEl);

  const handleToggleMenu = (event) => {
    setMenuEl(menuEl ? null : event.currentTarget);
  };

  function handleCloseMenu() {
    setMenuEl(null);
  }

  function handleSignOut(){
    handleCloseMenu();
    handleCloseDrawer();
    handleUserLogout();
  }

  return (
      <>
        <Box
            sx={{
              px: 2,
              py: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
        >
          <ProfileImageViewer
              size={'sm'}
              imgPath={profileImg}
              placeholderStyle={'user-icon'}
          />
          <Stack direction={'row'} justifyContent={'space-between'}
                 alignItems={'flex-end'}>
            <Stack mt={1}>
              <Typography variant={'h6'}>
                {name}
              </Typography>
              <Typography variant={'body2'}>
                {email}
              </Typography>
            </Stack>
            <IconButton
                size={'small'}
                onClick={handleToggleMenu}
            >
              <ArrowDropDown/>
            </IconButton>
          </Stack>
        </Box>
        <Menu
            open={isOpenMenu}
            onClose={handleCloseMenu}
            anchorEl={menuEl}
            placement={'bottom-end'}
        >
          <MenuItem onClick={handleSignOut}>{t('global.actions.logout')}</MenuItem>
        </Menu>
      </>
  );
}

function NavList({
                   navItems,
                   isAuthenticated,
                   component = 'nav',
                   disablePadding = false,
                   paddingLeft = '',
                 }) {
                  console.log('navitem', navItems)
  return (
      <List
          sx={{width: '100%'}}
          component={component}
          disablePadding={disablePadding}
      >
        {
            navItems?.length > 0 &&
            navItems.filter(x=>isAuthenticated || !!x?.isPublicRoute).map((item,index) => {
              if (!item.subMenuItems) {
                return <NavListItem key={index} item={item} paddingLeft={paddingLeft}/>;
              }
              if (item.subMenuItems) {
                return <NavListItemWithSubMenu key={index} item={item}/>;
              }
            })
        }
      </List>
  );
}

function NavListItem({item, paddingLeft, children}) {

  const Icon = item.icon;
  const navigateTo = useNavigate();
  const {handleCloseDrawer} = useContext(MobileNavContext);

  return (
      <ListItemButton
          sx={{pl: paddingLeft}}
          onClick={() => {
            navigateTo(item.route);
            handleCloseDrawer();
          }}
      >
        {/* <ListItemIcon>
          <Icon/>
        </ListItemIcon> */}
        <ListItemText primary={item.name}/>
        {children}
      </ListItemButton>
  );
}

function NavListItemWithSubMenu({item}) {
  const [isCollapsed, setIsIsCollapsed] = useState(true);

  function toggleCollapse(e) {
    e.stopPropagation();
    setIsIsCollapsed(prevState => !prevState);
  }

  return (
      <>
        <NavListItem item={item}>
          {
            <IconButton onMouseDown={(e) => {
              e.stopPropagation();
            }} onClick={toggleCollapse}>
              {isCollapsed ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          }
        </NavListItem>
        <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
          <NavList
              navItems={item.subMenuItems}
              disablePadding={true}
              component={'div'}
              paddingLeft={4}
          />
        </Collapse>
      </>
  );
}

export default SiteMobileNav;