import {
    Box,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useTheme,
  } from '@mui/material';
  import {Link, NavLink, useLocation} from 'react-router-dom';
  import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
  import ContainerFullWidth
    from '../../components/_Global/Containers/ContainerFullWidth';
  import {LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT} from '../../constants/ui';
  import {useEffect, useState} from 'react';
import { settingsDropdown } from '../../utils/analyticsEvents';
  
  function LayoutClubHeaderAuth({navItems,isAuthenticated}) {
    return  <Navbar navItems={navItems} isAuthenticated={isAuthenticated}/>;
  }
  
  function Navbar({navItems,isAuthenticated}) {
    const theme = useTheme();
    const location = useLocation();
    const isHome = location.pathname === '/';
  
    return <Box
        sx={{
          // borderBottom: `1px solid ${theme.palette.divider}`,
          height: '18px',
          display: 'flex',
          alignItems: 'center',
          mt: 4
        }}
    >
      <ContainerFullWidth>
        <Stack
            direction={'row'}
            spacing={3}
            alignItems={'center'}
            component={'ul'}
            sx={{
              padding: 0,
              height: LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
            }}
        >
          {
            navItems.filter(x=>isAuthenticated || !!x?.isPublicRoute).map((listItem, i) => {
  
              const submenuItems = listItem?.subMenuItems;
  
              return (
                  listItem.canAccess &&
                  <Box
                      key={i}
                      component={'li'}
                      sx={{
                        listStyle: 'none',
                        display: 'flex',
                        position: 'relative',
                      }}
                  >
                    <Stack
                        component={NavLink}
                        to={listItem.route}
                        end={true}
                        direction={'row'}
                        alignItems={'center'}
                        color={'text.primary'}
                        className={`${listItem.disabled &&
                        'disabled'} ${listItem.activeOnRoot && isHome &&
                        'active'}`}
                        sx={{
                          textDecoration: 'none',
                          fontSize: '14px',
                          fontWeight: 500,
                          padding: '5px 12px',
                          borderRadius: '30px',
                          '&.disabled': {
                            color: `${theme.palette.text.disabled} !important`,
                            pointerEvents: 'none',
                            cursor: 'none',
                          },
                          '&.active, &.active:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white
                          },
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white
                          },
                        }}
                    >
                      <Typography fontSize={'14px'} fontWeight={"500"}>
                        {listItem.name}
                      </Typography>
                    </Stack>
                    {
                        submenuItems &&
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'text.secondary',
                          cursor: 'pointer',
                        }}>
                          <SubMenu items={submenuItems}/>
                        </Box>
  
                    }
  
                  </Box>);
            })}
        </Stack>
      </ContainerFullWidth>
    </Box>;
  }
  
  function SubMenu({items}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    useEffect(()=>{

      if(open){
        settingsDropdown['clickOnDropdown']();
      }
    },[open])

    return (<>
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'text.secondary',
          }}
          onClick={handleClick}
      >
        {!open && <ArrowDropDown/>}
        {open && <ArrowDropUp/>}
      </Box>
  
      <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            mt: 1,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
  
      >
        {
          items.map((item, i) => {
            if (item.canAccess) {
              return <MenuItem
                  key={i}
                  component={Link}
                  to={item.route}
                  onClick={handleClose}
                  disabled={item.disabled}
                  sx={{
                    '.text': {
                      fontSize: '14px',
                    },
                  }}
              >
                <ListItemText
                    disableTypography
                    className={'text'}
                >{item.name}</ListItemText>
              </MenuItem>;
            }
          })
        }
  
      </Menu>
    </>);
  }
  
  export default LayoutClubHeaderAuth;
  